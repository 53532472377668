import { useContext, useEffect, useState } from 'react'
import { getLocation } from '../../../../utilities/geoLocations'
import SandBoxContext from '../../../../contexts/SandBoxContext'
import { Context } from '../../../../contexts/GlobalContext'

const LLMContextLoader = () => {
  const {sandboxState, sandboxSetState} = useContext(SandBoxContext)
  const {globalState} = useContext(Context)

  useEffect(() => {
    try {
      if(sandboxState?.isContextEnabled) {
        contextHandler()
      } else {
        contextHandler('clear')
      }
    } catch(err) {
      contextHandler('clear')
      console.log('Defaulting to disabled context.')
    }
  }, [])

  useEffect(() => {
    if(sandboxState?.isContextEnabled) {
      contextHandler()
    } else {
      contextHandler('clear')
    }
  }, [sandboxState?.isContextEnabled])

  useEffect(() => {
    if(sandboxState?.isContextEnabled) {
      contextHandler()
    } else {
      contextHandler('clear')
    }
  }, [globalState?.user?.webServices?.services])

  const contextHandler = async (mode='write', loadFull=true) => {
    let contexts = {}
    if(mode === 'clear') {
      sandboxSetState({contexts})
      localStorage.removeItem('context_enabled')
      sandboxSetState({isContextLoading: false})
    } else {
      if(!sandboxState?.isContextLoading) {
        sandboxSetState({isContextLoading: true})
        if(globalState?.user?._id) {
          contexts['name'] = {
            title: 'My Name',
            key: 'name',
            value: globalState?.user?.firstName || 'Unknown',
            icon: 'fal fa-user'
          }
          contexts['email'] = {
            title: 'My Email',
            key: 'email',
            value: getEmailContext(globalState) || 'Unknown',
            icon: 'fal fa-envelope'
          }
        } else {
          delete contexts['name']
          delete contexts['email']
        }
        contexts['time'] = {
          title: 'Local Time',
          key: 'time',
          value: '[currTime]', // filled out by contextPanel state 'currTime'
          icon: 'fal fa-clock'
        }
        let locationContext = await getLocationContext()
        contexts[locationContext.key] = locationContext
        
        // todo: load location half of the time
        
        sandboxSetState({contexts, isContextLoading: false})
        localStorage.setItem('context_enabled', 'true')
      }
    }
  }
  return null
}

const getEmailContext = (globalState) => {
  const defaultEmail = globalState?.user?.email || null
  const services = globalState?.user?.webServices?.services || []
  const integrateEmail = services.find(s => s.serviceID === 'gmail-send' && s.status === 'active')?.serviceUser || null
  return integrateEmail || defaultEmail || null
}

const getLocationContext = async () => {
  return new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      return navigator.geolocation.getCurrentPosition(async position => {
        var latitude = position.coords.latitude
        var longitude = position.coords.longitude
        try {
          let result = await getLocation(latitude, longitude)
          result = `${result.postcode}, ${result.city}, ${result.country}`
          resolve({
            title: 'Location',
            key: 'location',
            value: result,
            icon: 'fal fa-map-marker-alt'
          })
        } catch(err) {
          console.error(err)
          reject({
            title: 'Location',
            key: 'location',
            value: 'Unknown',
            icon: 'fal fa-map-marker-alt'
          })
        }
      }, (error) => {
        console.error("Error getting geolocation:", error)
        reject({
          title: 'Location',
          key: 'location',
          value: 'Unknown',
          icon: 'fal fa-map-marker-alt'
        })
      })
    } else {
      console.error("Geolocation is not supported by this browser.")
      reject({
        title: 'Location',
        key: 'location',
        value: 'Unknown',
        icon: 'fal fa-map-marker-alt'
      })
    }
  })
}

export default LLMContextLoader