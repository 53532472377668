import React, { useContext, useState } from 'react'
import './styles.scss'
import { FlowBasic, FlowButton, FlowDropDown, FlowEnum, FlowInput, FlowModal, FlowSamplePrompts, FlowStackedBody, FlowTextBox } from './flow-blocks'
import supportedDataTypes from '../../pages/SpeedRun/sandbox/dataType'
import SandBoxContext from '../../contexts/SandBoxContext'

const FlowFunctionFillup = ({objectName, objectType, onClose=() => {}}) => {
  // const windowID = 'lvl2-fillup'
  const {getObjectDetails, isValidVariable, isValidFunction, applyObjectDetails, sandboxSetState} = useContext(SandBoxContext)
  const {_title, _dataType, _description, _isRequired, _enums, _samplePrompts, _outputPrompt} = getObjectDetails(objectName, objectType)
  const [title, setTitle] = useState(_title)
  const [dataType, setDataType] = useState(_dataType)
  const [enums, setEnums]  = useState(_enums)
  const [isRequired, setIsRequired] = useState(_isRequired)
  const [description, setDescription] = useState(_description)
  const [outputPrompt, setOutputPrompt] = useState(_outputPrompt)
  const [samplePrompts, setSamplePrompts] = useState(_samplePrompts)
  // console.log('type', objectType, 'name', objectName)
  if(!objectType && !objectName) {
    return null
  }

  var requiredSelection = 'Select'
  if(isRequired === null) requiredSelection = 'Select'
  else if(isRequired === true) requiredSelection = 'Yes'
  else if(isRequired === false) requiredSelection = 'No'

  var isValid = false
  if(objectType === 'function') {
    isValid = isValidFunction({title, description, samplePrompts})
  } else if(objectName) {
    isValid = isValidVariable({title, dataType, isRequired, description, enum: enums})
  }

  var doneButton = (
    <div style={{marginTop: '10px', textAlign: 'center'}}>
      <FlowButton style={{
        lineHeight: 1, fontSize: '12px', padding: '3px 7px',
        userSelect: 'none'
      }}
        onClick={() => {
          // close
          onClose()
          sandboxSetState({objectSetup: null})
        }}
      >
        <><i className="fal fa-times" style={{color: 'crimson'}}></i>&nbsp;Cancel</>
      </FlowButton>&nbsp;&nbsp;&nbsp;&nbsp;
      <FlowButton style={{
        lineHeight: 1, fontSize: '12px', padding: '3px 7px',
        userSelect: 'none',
        cursor: isValid ? 'pointer' : 'not-allowed',
        filter: isValid ? null : 'brightness(100%)'
      }}
        onClick={() => {
          if(isValid) {
            let object = null
            if(objectType === 'function') {
              object = { title, description, samplePrompts, outputPrompt }
            } else {
              object = { title, description, dataType, isRequired, enum: enums }
            }
            applyObjectDetails(objectName, objectType, object)
            sandboxSetState({objectSetup: null})
          }
        }}
      >
        {
          isValid ?
            <><i className="fal fa-check" style={{color: 'rgb(29, 191, 113)'}}></i>&nbsp;DONE</>
            :
            <><i className="fal fa-exclamation-circle" style={{color: 'orange'}}></i>&nbsp;Pending</>
        }
      </FlowButton>
    </div>
  )

  if(objectType === 'function') {
    let placeholder = "Provide a concise description of the function's purpose, ideally with an example illustrating its usage. Specify the types of arguments it accepts as input and the type of output it produces."
    let entryMsgPlaceholder = "Include a function-specific system message to adjust the behavior of the LLM. Adding such a message may lead to more stable outcomes. Additionally, you can suggest to the LLM how the output of the function should be formatted."
    return (
      <FlowModal>
        <FlowBasic
          title='Function Description'
          titleIcon={<i className="fal fa-function" style={{color: 'rgb(97, 176, 239)'}}></i>}
          border={'2px solid rgba(255, 255, 255, 0.2)'}
        >
          <FlowStackedBody showStep={false}>
            <div style={{width: '100%', display: 'inline-flex', alignItems: 'center'}}>
              <div style={{flexGrow: 1, fontFamily: 'Roboto Mono'}}>Function Name</div>
              <FlowInput
                value={title}
                disabled={true}
                placeHolder='function_name'
                onChange={(e) => {
                  setTitle(String(e.target.value).trim())
                }}
              />
            </div>
          </FlowStackedBody>
          <br/>
          <FlowStackedBody showStep={false}>
            <div style={{width: '100%', display: 'inline-flex', alignItems: 'center'}}>
              <div style={{flexGrow: 1, fontFamily: 'Roboto Mono'}}>Function Description</div>
            </div>
          </FlowStackedBody>
          <div className='sv2-flow-stacked-body' style={{height: 'auto', alignItems: 'unset'}}>
            <div className='title-icon'>
              <div className='square'
                style={{bottom: '12px'}}
              ></div>
            </div>
            <div style={{marginTop: '4px', width: '100%'}}>
              <FlowTextBox
                value={description}
                placeHolder={placeholder}
                onChange={e => {setDescription(e.target.value)}}
              />
            </div>
          </div>
          <br/>
          <FlowStackedBody showStep={false}>
            <div style={{width: '100%', display: 'inline-flex', alignItems: 'center'}}>
              <div style={{flexGrow: 1, fontFamily: 'Roboto Mono'}}>Entry Prompt (Optional)</div>
            </div>
          </FlowStackedBody>
          <div className='sv2-flow-stacked-body' style={{height: 'auto', alignItems: 'unset'}}>
            <div className='title-icon'>
              <div className='square'
                style={{bottom: '12px'}}
              ></div>
            </div>
            <div style={{marginTop: '4px', width: '100%'}}>
              <FlowTextBox
                value={outputPrompt}
                placeHolder={entryMsgPlaceholder}
                onChange={e => {setOutputPrompt(e.target.value)}}
              />
            </div>
          </div>
          <br/>
          <FlowStackedBody showStep={false}>
            <div style={{width: '100%', display: 'inline-flex', alignItems: 'center'}}>
              <div style={{flexGrow: 1, fontFamily: 'Roboto Mono'}}>Sample Prompt</div>
            </div>
          </FlowStackedBody>
          <div className='sv2-flow-stacked-body' style={{height: 'auto', alignItems: 'unset'}}>
            <div className='title-icon'>
              <div className='square'
                style={{bottom: '12px'}}
              ></div>
            </div>
            <div style={{marginTop: '4px', width: '100%'}}>
              <FlowSamplePrompts
                defaultSamples={samplePrompts}
                placeHolder={'Include one or more examples of prompts that could make use of this function.'}
                onAdd={values => setSamplePrompts(values)}
              />
            </div>
          </div>
          {doneButton}
        </FlowBasic>
      </FlowModal>
    )
  }
  let placeholder = "Provide a concise description of the argument's purpose."
  return (
    <FlowModal>
      <FlowBasic
        title='Keyword Arguments'
        titleIcon={<i className="fal fa-brackets-curly" style={{color: 'rgb(229, 192, 123)'}}></i>}
        border={'2px solid rgba(255, 255, 255, 0.2)'}
      >
        <FlowStackedBody showStep={false}>
          <div style={{width: '100%', display: 'inline-flex', alignItems: 'center'}}>
            <div style={{flexGrow: 1, fontFamily: 'Roboto Mono'}}>Argument Name</div>
            <FlowInput
              value={title}
              placeHolder='argument_name'
              onChange={(e) => {
                setTitle(String(e.target.value).trim())
              }}
            />
          </div>
        </FlowStackedBody>
        <FlowStackedBody showStep={false}>
          <div style={{width: '100%', display: 'inline-flex', alignItems: 'center'}}>
            <div style={{flexGrow: 1, fontFamily: 'Roboto Mono'}}>Is Required</div>
            <FlowDropDown
              selected={requiredSelection}
              options={['Yes', 'No']}
              onSelection={elem => {
                if(elem === 'Yes') {
                  setIsRequired(true)
                } else if(elem === 'No') {
                  setIsRequired(false)
                }
              }}
              initialMsg='Select Data Type'
            />
          </div>
        </FlowStackedBody>
        <FlowStackedBody showStep={false}>
          <div style={{width: '100%', display: 'inline-flex', alignItems: 'center'}}>
            <div style={{flexGrow: 1, fontFamily: 'Roboto Mono'}}>Datatype</div>
            <FlowDropDown
              selected={dataType}
              options={supportedDataTypes}
              onSelection={elem => {
                setDataType(elem)
              }}
              initialMsg='Select Data Type'
            />
          </div>
        </FlowStackedBody>
        {
          ['int', 'float', 'str'].includes(dataType) ?
          <FlowEnum 
            dType={dataType}
            defaultEnums={enums}
            onAdd={(values) => {setEnums(values)}}
          />
          :
          null
        }
        <br/>
        <FlowStackedBody showStep={false}>
          <div style={{width: '100%', display: 'inline-flex', alignItems: 'center'}}>
            <div style={{flexGrow: 1, fontFamily: 'Roboto Mono'}}>Argument Description</div>
          </div>
        </FlowStackedBody>
        <div className='sv2-flow-stacked-body' style={{height: 'auto', alignItems: 'unset'}}>
          <div className='title-icon'>
            <div className='square'
              style={{bottom: '12px'}}
            ></div>
          </div>
          <div style={{marginTop: '4px', width: '100%'}}>
            <FlowTextBox
              value={description}
              placeHolder={placeholder}
              onChange={e => {setDescription(e.target.value)}}
            />
          </div>
        </div>
        {doneButton}
      </FlowBasic>
    </FlowModal>
  )
}

export default FlowFunctionFillup