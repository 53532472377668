import { useContext, useEffect } from 'react'
import SandBoxContext from '../../../../contexts/SandBoxContext'
import { Context } from '../../../../contexts/GlobalContext'
import {getServiceWithID} from './supportedServices'
const LLMServiceLoader = () => {
  const {sandboxState, sandboxSetState} = useContext(SandBoxContext)
  const {globalState} = useContext(Context)
  // const webServices = globalState?.user?.webServices?.services || []
  // console.log('Web services', webServices)

  useEffect(() => {
    try {
      if(sandboxState?.isServiceEnabled) {
        serviceLoadHandler()
      } else {
        serviceLoadHandler('clear')
      }
    } catch(err) {
      console.log('Error loading service lists.')
    }
  }, [])
  
  useEffect(() => {
    if(sandboxState?.isServiceEnabled) {
      serviceLoadHandler()
    } else {
      serviceLoadHandler('clear')
    }
  }, [sandboxState?.isServiceEnabled])

  useEffect(() => {
    if(sandboxState?.isServiceEnabled) {
      serviceLoadHandler()
    } else {
      serviceLoadHandler('clear')
    }
  }, [globalState?.user?.webServices?.services])

  const serviceLoadHandler = async (mode='write') => {
    let services = {}
    if(mode === 'clear') {
      sandboxSetState({services, isServiceEnabled: false})
      localStorage.removeItem('service_enabled')
    } else {
      let serviceContexts = await getServiceContext(globalState)
      serviceContexts.forEach(service => {
        services[service.serviceID] = service
      })
      sandboxSetState({services, isServiceLoading: false})
      localStorage.setItem('service_enabled', 'true')
    }
  }
  
  return null
}


// note: gets all the services from the globablContext user state (user state is the db snapshot)
const getServiceContext = async (globalState) => {
  let webServices = globalState?.user?.webServices?.services || []
  let value = ''
  let isActive = false
  let icon = 'fal fa-info-circle'
  let serviceID = 'empty-service'   // default empty serviceID when nothing is integrated
  let title = 'No Services Added'
  let extra = ''

  let emailService = webServices.find(service => service?.serviceID === 'gmail-send')

  if(emailService) {
    let service = getServiceWithID(emailService?.serviceID)
    // dynamic details
    value = emailService?.serviceUser || 'Unknown'
    isActive = emailService?.status === 'active'
    serviceID = emailService?.serviceID
    // static details
    icon = service.icon
    title = service.serviceKey
    extra = service.extra
  }
  
  return [{ title, value, icon, serviceID, isActive, extra }]
}


export default LLMServiceLoader