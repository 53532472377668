import React, { useContext, useEffect, useState } from 'react'
import '../../../components/cloud-panels/styles.scss'
import Agent from '../title'
import {RightPanel, RightPanelBasicButton, RightPanelBasicTitle, InfoToolTip} from './panel-utils'
import FlowActionInitiator, { FlowActionDone } from '../../../components/flow-ui/action-initiator'
import FunctionEntry from '../../../components/flow-ui/function-entry'
import FlowFunctionFillup from '../../../components/flow-ui/function-fillup'
import { FlowBase, FlowButton, FlowConnector } from '../../../components/flow-ui/flow-blocks'
import SandBoxContext from '../../../contexts/SandBoxContext'

const FunctionIntegrationTitle = () => {
  const {sandboxState, sandboxSetState, openWindow} = useContext(SandBoxContext)
  var menuButton = null
  if(!sandboxState?.windowStack?.includes('lvl1-instructions')) {
    menuButton = (
      <FlowButton
        onClick={() => {openWindow('lvl1-instructions')}}
        style={{marginLeft: '10px'}}
      >Menu</FlowButton>
    )
  }
  return(
    <RightPanelBasicTitle>
      <div className='sndbx-panel-close'
        onClick={() => {
          sandboxSetState({showSidePanel: false})
        }}
      >
        <i className="far fa-chevron-right"></i>
      </div>
      <i className="fal fa-drafting-compass" style={{color: 'rgb(29, 191, 113)'}}></i>&nbsp;&nbsp;Integrate Action&nbsp;&nbsp;
      <span style={{fontSize: '14px'}}>
        <InfoToolTip>
          Integrating an action requires defining the functions body and writing about what the function does and the parameters in details.
        </InfoToolTip>
      </span>
      {menuButton}
    </RightPanelBasicTitle>
  )
}

const FunctionInstructions = () => {
  // const windowID = 'lvl1-instructions'
  const {openWindow, temporaryStateManagement, loadExample} = useContext(SandBoxContext)
  useEffect(() => {
    // reset to original state from example state if there's any state saved
    if(localStorage.getItem('example-reset-flag') === 'true') {
      try {
        localStorage.removeItem('example-reset-flag')
        temporaryStateManagement('load', 'original-state') // removes state after loading
      } catch(err) {
        console.log(err)
      }
    }
    
    if(localStorage.getItem('code-initiation') === 'true') {
      try {
        localStorage.removeItem('code-initiation')
        temporaryStateManagement('load')    // removes state after loading
      } catch(err) {
        console.log(err)
      }
    }
  }, [])
  return(
    <RightPanel>
      <FunctionIntegrationTitle/>
      <div style={{width: '100%', height: '100%', overflowY: 'scroll', padding: '10px'}}>
        <FlowBase style={{border: '2px solid rgba(255, 255, 255, 0.2)', fontSize: '12px', lineHeight: 1.4, color: 'rgb(215, 215, 215)'}}>
          <Agent style={{color: 'rgb(29, 191, 113)'}}/> is built with capability and versatility in mind. You can define your own functions in <strong>Python</strong> and seamlessly integrate them into <Agent/>. Your functions become part of <Agent add={"\'s"}/> ever-expanding capabilities, available for both you and the community to utilize.
        </FlowBase>
        <RightPanelBasicButton
          onClick={() => {openWindow('lvl1-details')}}
        >
          Integrate Action
        </RightPanelBasicButton>
        <RightPanelBasicButton
          onClick={() => {
            loadExample()
            openWindow('lvl1-funcexample')
          }}
        >
          Show Example
        </RightPanelBasicButton>
        <RightPanelBasicButton
          onClick={() => {openWindow('lvl1-funclist')}}
        >
          My Functions
        </RightPanelBasicButton>
        <RightPanelBasicButton
          onClick={() => {openWindow('lvl1-functesting')}}
        >
          Test Function
        </RightPanelBasicButton>
      </div>
    </RightPanel>
  )
}

const FunctionLists = () => {
  // const windowID = 'lvl1-funclist'
  const [isLoading, setIsLoading] = useState(false)
  const {sandboxState, loadFunctions} = useContext(SandBoxContext)

  useEffect(() => {
    if(!isLoading) {
      setIsLoading(true)
      loadFunctions(() => {
        setIsLoading(false)
      }, () => {
        setIsLoading(false)
      })
    }
  }, [])

  return(
    <RightPanel>
      <FunctionIntegrationTitle/>
      <div style={{width: '100%', height: '100%', overflowY: 'scroll', padding: '10px'}}>
        <FlowBase style={{border: '2px solid rgba(255, 255, 255, 0.2)', fontSize: '12px', lineHeight: 1.4, color: 'rgb(215, 215, 215)'}}>
          You can access the functions you've written here. They are embedded and immediately added to <Agent add="'s"/> capabilities under Experimental Actions.
        </FlowBase>
        {
          sandboxState?.myFunctions?.length > 0 ?
          sandboxState.myFunctions.map((f, i) => (
            <div style={{marginTop: '10px'}}>
              <FlowBase style={{border: '2px solid rgba(255, 255, 255, 0.2)', fontSize: '12px', lineHeight: 1.4, color: 'rgb(215, 215, 215)'}} key={i}>
                <span style={{backgroundColor: 'rgba(255, 255, 255, 0.1)', padding: '2px 4px', borderRadius: '4px', lineHeight: 1, color: 'rgb(97, 176, 239)', fontFamily: 'Roboto Mono'}}>{f?.title}</span>
                &nbsp;&nbsp;<span style={{backgroundColor: 'rgba(255, 255, 255, 0.1)', padding: '2px 4px', borderRadius: '4px', lineHeight: 1, color: 'rgb(29, 191, 113)', fontFamily: 'Roboto Mono'}}>{f?.status}</span>
                <br/><br/>
                <strong>Description</strong>
                <div style={{
                  marginTop: '4px', padding: '7px', backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  borderRadius: '7px', fontFamily: 'Roboto Mono', fontSize: '11px', lineHeight: 1.5
                }}>
                  {f?.description}
                </div>
              </FlowBase>
            </div>
          ))
          :
          <div style={{marginTop: '50px', width: '100%', textAlign: 'center', color: 'rgb(70, 73, 78)', fontWeight: 700, fontSize: '20px'}}>You don't have any functions yet</div>
        }
      </div>
    </RightPanel>
  )
}

const FunctionDetails = ({updatePrompt=()=>{}}) => {
  // const windowID = 'lvl1-details'
  const {sandboxState, isValidFull} = useContext(SandBoxContext)
  var objectName = sandboxState?.objectSetup?.objectName || ''
  var objectType = sandboxState?.objectSetup?.objectType || ''
  var functionTitle = sandboxState?.functionDetails?.title || ''
  var output = sandboxState?.functionDetails?.output || ''
  var {isValid, warningFlags} = isValidFull()
  return(
    <RightPanel>
      <FunctionIntegrationTitle/>
      <div style={{width: '100%', height: '100%', overflowY: 'scroll', padding: '10px'}}>
        <FlowActionInitiator/>
        {
          functionTitle ? 
          <>
            <FlowConnector length={30}/>
            <FunctionEntry warningFlags={warningFlags}/>
          </>
          :
          null
        }
        {
          output ?
          <>
            <FlowConnector length={65} hasMid={true}>
              <FlowButton style={{cursor: 'default'}}>
                <i className="fal fa-chart-network" style={{color: 'rgb(29, 191, 113)', filter: 'brightness(125%)'}}></i>
                <span style={{marginLeft: '4px'}}>Transition (Coming Soon)</span>
              </FlowButton>
            </FlowConnector>
            <FlowActionDone isValid={isValid} updatePrompt={updatePrompt}/>
          </>
          :
          null
        }
        {
          objectType || objectName ?
          <FlowFunctionFillup
            objectName={objectName}
            objectType={objectType}
          />
          :
          null
        }
      </div>
    </RightPanel>
  )
}

const FunctionExample = ({updatePrompt=()=>{}}) => {
  // const windowID = 'lvl1-details'
  const {sandboxState, isValidFull} = useContext(SandBoxContext)
  var objectName = sandboxState?.objectSetup?.objectName || ''
  var objectType = sandboxState?.objectSetup?.objectType || ''
  var functionTitle = sandboxState?.functionDetails?.title || ''
  var output = sandboxState?.functionDetails?.output || ''
  var {isValid, warningFlags} = isValidFull()
  return(
    <RightPanel>
      <FunctionIntegrationTitle/>
      <div style={{width: '100%', height: '100%', overflowY: 'scroll', padding: '10px'}}>
        <FlowActionInitiator exampleMode={true}/>
        {
          functionTitle ? 
          <>
            <FlowConnector length={30}/>
            <FunctionEntry warningFlags={warningFlags}/>
          </>
          :
          null
        }
        {
          output ?
          <>
            <FlowConnector length={65} hasMid={true}>
              <FlowButton style={{cursor: 'default'}}>
                <i className="fal fa-chart-network" style={{color: 'rgb(29, 191, 113)', filter: 'brightness(125%)'}}></i>
                <span style={{marginLeft: '4px'}}>Transition (Coming Soon)</span>
              </FlowButton>
            </FlowConnector>
            <FlowActionDone isValid={isValid} updatePrompt={updatePrompt} exampleMode={true}/>
          </>
          :
          null
        }
        {
          objectType || objectName ?
          <FlowFunctionFillup
            objectName={objectName}
            objectType={objectType}
          />
          :
          null
        }
      </div>
    </RightPanel>
  )
}



export default FunctionInstructions
export {FunctionDetails, FunctionIntegrationTitle, FunctionLists, FunctionExample}