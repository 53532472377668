const parseParams = (paramGroup) => {
  if(!paramGroup) return []
  
  const parameterRegex = /(\w+)(\s*:\s*(\w+))?(\s*\=[^,]*)?/g
  let paramMatch
  let params = []
  while ((paramMatch = parameterRegex.exec(paramGroup)) !== null) {
      const title = paramMatch[1]
      const dataType = paramMatch[3] || ''
      const isRequired = paramMatch[4] ? false : true
      params.push({
          title, dataType, isRequired, description: '', enum: []
      })
  }
  return params
}

const parseFunctions = (script) => {
  const functionRegex = /def\s+(\w+)\(([^)]*)\)\s*(->\s*\w+)?\s*:/g
  const functions = []
  let match
  while ((match = functionRegex.exec(script)) !== null) {
      const title = match[1]
      const paramStr = match[2]
      const output = String(match[3] || '').replace(/->/g, '').trim()
      const parameters = parseParams(paramStr)
      functions.push({
          title, 
          description: '',
          parameters,
          samplePrompts: [],
          output
      })
  }
  return functions
}
export default parseFunctions