import React, { useContext, useEffect, useRef, useState } from 'react'
import './styles.scss'
import { useAutosizeTextArea } from '../../utilities/handyFunctions'
import Spinner from '../../components/spinner'
import CodeMirror from '@uiw/react-codemirror'
import { python } from '@codemirror/lang-python'
import { json } from '@codemirror/lang-json'
import { atomone } from '@uiw/codemirror-theme-atomone'
import {ConsoleContext} from '../../contexts/ConsoleContext'
import Axios from '../../utilities/axios'
import { useInterval, useIsMounted, useStatePro } from '../../utilities/hooks'
import SandBoxContext from '../../contexts/SandBoxContext'
import ToolTip from '../../components/tool-tip'
import Agent from './title'
import { Context } from '../../contexts/GlobalContext'

const AIPanel = ({
  onSubmit = () => {},
  isLoading,
  onChange,
  value,
  placeHolder='',
  proMode=false,
  onLLMSelection=() => {},
  onActionSelection=() => {},
  testMode=false,
  onTestToggle=() => {}
}) => {
  const textAreaRef = useRef(null)
  const [isPromptFocused, setIsPromptFocused] = useState(false)
  const {sandboxState, sandboxSetState} = useContext(SandBoxContext)
  const {globalState} = useContext(Context)
  useAutosizeTextArea(textAreaRef.current, value)
  var panelMode = 'action'
  if(sandboxState?.panelMode) panelMode = sandboxState?.panelMode
  return (
    <div className='sv2-spdrn-ai-panel-container'>
      {
        proMode ? 
        <div style={{border: '2px solid rgb(75, 80, 90)', borderRadius: '10px', padding: '10px', backgroundColor: 'rgb(35, 38, 45)'}}>
          <div className='sv2-spdrn-ai-input-pro-ctrlbox'>
            <ToolTip
              styles={{backgroundColor: 'transparent'}}
              view={<TipCard>Ask <Agent/> to do something.</TipCard>}
              cursor='pointer'
              onClick={() => { sandboxSetState({panelMode: 'action'}) }}
            >
              <div className={`sv2-spdrn-ai-input-pro-btn${panelMode === 'action' ? ' pro-selected' : ''}`}>
                <span style={{fontSize: '16px'}}><i className="fal fa-project-diagram pro-icon"></i></span>Action
              </div>
            </ToolTip>
            <ToolTip
              styles={{backgroundColor: 'transparent'}}
              view={<TipCard>Integrate your own Python function into <Agent/>.</TipCard>}
              cursor='pointer'
              onClick={() => { sandboxSetState({panelMode: 'integrate', showSidePanel: true}) }}
            >
              <div className={`sv2-spdrn-ai-input-pro-btn${panelMode === 'integrate' ? ' pro-selected' : ''}`}>
                <span style={{fontSize: '16px'}}><i className="fal fa-drafting-compass pro-icon"></i></span>Integrate
              </div>
            </ToolTip>
            <ToolTip
              styles={{backgroundColor: 'transparent'}}
              view={<TipCard>Configure the context <Agent/> knows about you.</TipCard>}
              cursor='pointer'
              onClick={() => { sandboxSetState({panelMode: 'context', showSidePanel: true}) }}
            >
              <div className={`sv2-spdrn-ai-input-pro-btn${panelMode === 'context' ? ' pro-selected' : ''}`}>
                <span style={{fontSize: '16px'}}><i className="fal fa-book pro-icon"></i></span>Context
              </div>
            </ToolTip>
            <ToolTip
              styles={{backgroundColor: 'transparent'}}
              view={<TipCard>Add web services to Nelima</TipCard>}
              cursor='pointer'
              onClick={() => { sandboxSetState({panelMode: 'services', showSidePanel: true})}}
            >
              <div className={`sv2-spdrn-ai-input-pro-btn${panelMode === 'services' ? ' pro-selected' : ''}`}>
                <span style={{fontSize: '16px'}}><i className="fal fa-sparkles pro-icon"></i></span>Services
              </div>
            </ToolTip>
            {/* <ToolTip
              styles={{backgroundColor: 'transparent'}}
              view={<TipCard>Ask Nelima to do something later (Coming Soon)</TipCard>}
            >
              <div className='sv2-spdrn-ai-input-pro-btn pro-disabled'>
                <span style={{fontSize: '16px'}}><i className="fal fa-clock pro-icon"></i></span>Schedule
              </div>
            </ToolTip> */}
            <ToolTip
              styles={{backgroundColor: 'transparent'}}
              view={<TipCard>Give Nelima file access<br/>(Coming Soon)</TipCard>}
            >
              <div className='sv2-spdrn-ai-input-pro-btn pro-disabled'>
                <span style={{fontSize: '16px'}}><i className="fal fa-folders pro-icon"></i></span>Files
              </div>
            </ToolTip>
          </div>
          <form 
            className='sv2-spdrn-ai-input-form'
            style={{backgroundColor: 'rgb(50, 53, 60)', borderRadius: '5px'}}
            action='#'
          >
            <div className='sv2-spdrn-ai-icon'>
              {
                isLoading ?
                <Spinner style={{borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)'}}/>
                :
                <i className={`${isPromptFocused ? 'fas fa-bolt glow': 'far fa-bolt'}`} aria-hidden={true}></i>
              }
            </div>
            <textarea
              id='sv2-spdrn-ai-id'
              className='sv2-spdrn-ai-input-div pro-form'
              rows={1}
              placeholder={placeHolder}
              ref={textAreaRef}
              readOnly={isLoading}
              onChange={(e) => { if(onChange) onChange(e) }}
              data-gramm={false} // eliminate grammarly
              data-gramm_editor={false}
              data-enable-grammarly={false}
              onKeyDown={e => { 
                if(e.key === 'Enter' && !e.shiftKey) {
                  onSubmit()
                  e.preventDefault()
                }
              }}
              onFocus={() => { setIsPromptFocused(true) }}
              onBlur={() => { setIsPromptFocused(false) }}
              value={value || ''}
            />
            {
              isLoading ?
              <div className="sv2-spdrn-ai-input-submit" onClick={() => {
                try {
                  console.log('calling abort')
                  sandboxState.axiosController.abort()
                } catch(err) {
                  console.log('Can not cancel')
                }
              }}>
                <i className="fas fa-stop-circle"></i>
              </div>
              :
              <div className="sv2-spdrn-ai-input-submit" onClick={onSubmit}>
                <i className="fas fa-arrow-circle-right"></i>
              </div>  
            }
          </form>
          <div style={{width: '100%', fontSize: '12px', fontWeight: 500, color: 'rgb(150, 150, 150)', paddingTop: '8px', marginBottom: '-3px'}}>
            <div className='sv2-spdrn-pro-option-base'>LLM</div>
            <div className='sv2-spdrn-pro-option-base option-spacing option-btn option-selected'
              onClick={() => {onLLMSelection('chat-gpt')}}
            >Multi-Agent</div>
            {/* <div className='sv2-spdrn-pro-option-base option-disabled'
              onClick={() => {onLLMSelection('llama-2')}}
            >Llama-2</div> */}
            <span style={{fontSize: '16px', cursor: 'default'}}>|</span>
            <div className='sv2-spdrn-pro-option-base'>Actions</div>
            {
              testMode ?
              <>
                <div className='sv2-spdrn-pro-option-base option-spacing option-btn'
                  onClick={() => onActionSelection('experimental')}
                >Experimental</div>
                <div className='sv2-spdrn-pro-option-base option-spacing option-disabled'
                  // onClick={() => onActionSelection('general')}
                >General</div>
                <div className='sv2-spdrn-pro-option-base option-btn option-selected'
                  style={{color: 'orange'}}
                  onClick={onTestToggle}
                >Test Mode&nbsp;<i className="far fa-stop-circle"></i></div>
              </>
              :
              <>
                <div className='sv2-spdrn-pro-option-base option-spacing option-btn option-selected'
                  onClick={() => onActionSelection('experimental')}
                >Experimental</div>
                <div className='sv2-spdrn-pro-option-base option-spacing option-disabled'
                  // onClick={() => onActionSelection('general')}
                >General</div>
                {
                  sandboxState?.erroredPrompt === true ?
                  <div className='sv2-spdrn-pro-option-base'
                    style={{float: 'right', color: 'crimson'}}
                    // onClick={() => onActionSelection('general')}
                  ><strong>Try again later</strong></div>
                  :
                  null
                }
              </>
            }
          </div>
        </div>
        :
        <form 
          className='sv2-spdrn-ai-input-form'
          action='#'
        >
          {/* <AIPrompt/> */}
          <div className='sv2-spdrn-ai-icon'>
            {
              isLoading ?
              <Spinner style={{borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)'}}/>
              :
              <i className={`${isPromptFocused ? 'fas fa-bolt glow': 'far fa-bolt'}`} aria-hidden={true}></i>
            }
          </div>
          <textarea
            id='sv2-spdrn-ai-id'
            className='sv2-spdrn-ai-input-div'
            rows={1}
            placeholder='Ask AI to write code or run something'
            ref={textAreaRef}
            readOnly={isLoading}
            onChange={(e) => { if(onChange) onChange(e) }}
            data-gramm={false} // eliminate grammarly
            data-gramm_editor={false}
            data-enable-grammarly={false}
            onKeyDown={e => { 
              if(e.key === 'Enter' && !e.shiftKey) {
                onSubmit()
                e.preventDefault()
              }
            }}
            onFocus={() => { setIsPromptFocused(true) }}
            onBlur={() => { setIsPromptFocused(false) }}
            value={value || ''}
          />
        </form>
      }
    </div>
  )
}

const TipCard = ({children}) => (
  <div style={{
    background: 'rgb(35, 38, 45)',
    border: '2px solid rgb(72, 74, 78)',
    color: 'rgb(215, 215, 215)',
    borderRadius: '4px',
    padding: '3px 6px',
    fontSize: '10px',
    textAlign: 'center'
  }}>
    {children}
  </div>
)

const CodePanel = () => {
  const { editorRef, state, registerScriptUpdate } = useContext(ConsoleContext)
  useEffect(() => {
    setTimeout(() => {
      try {
        var t = editorRef.current.editor.getElementsByTagName('grammarly-extension')
        t[0].style.setProperty('display', 'none')
      } catch (error) {
        // nothing to do here
      }
    }, 500)
    setTimeout(() => {
      try {
        var t = editorRef.current.editor.getElementsByTagName('grammarly-extension')
        t[0].style.setProperty('display', 'none')
      } catch (error) {
        // nothing to do here
      }
    }, 3000)
  }, [])
  // console.log(state.activeComponent, state.activeEditor)
  return(
    <CodeMirror
      ref={editorRef}
      value={
        state.activeEditor === 'compute' ? (state.mainContent || '')
          :
          state.activeEditor === 'deploy' ? (state.deployContent || '# The Deployment Function is triggered when an API endpoint is called.\n# Write your code logic inside the process function.\n\ndef process(function_input):\n  ## function logic goes here\n  ## The returned output will be included in the response body.\n  return None') : ''
      }
      onChange={registerScriptUpdate}
      className='sv2-codemirror-configure'
      height='100% !important'
      editable={true}
      autoFocus={true}
      theme={atomone}
      extensions={[python()]}
      indentWithTab={true}
      basicSetup={{
        lineNumbers: true,
        highlightActiveLineGutter: true,
        highlightSpecialChars: true,
        history: true,
        foldGutter: true,
        drawSelection: true,
        dropCursor: true,
        allowMultipleSelections: true,
        indentOnInput: true,
        syntaxHighlighting: true,
        bracketMatching: true,
        closeBrackets: true,
        autocompletion: true,
        rectangularSelection: true,
        crosshairCursor: true,
        highlightActiveLine: true,
        highlightSelectionMatches: true,
        closeBracketsKeymap: true,
        defaultKeymap: true,
        searchKeymap: true,
        historyKeymap: true,
        foldKeymap: true,
        completionKeymap: true,
        lintKeymap: true
      }}
    />
  )
}

const LogViewer = () => {
  const { state, setState, downloadOutput } = useContext(ConsoleContext)
  const [result, setResult] = useState({})
  const [isLoadingViewer, setIsLoadingViewer] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const [isLoadingLog, setIsLoadingLog] = useState(false)
  const [logContent, setLogContent] = useState('')
  const isMounted = useIsMounted()
  
  const logRef = useRef(null)
  const [activeLog, setActiveLog] = useStatePro('')
  
  useEffect(() => {
    syncLogs()
  }, [])

  useEffect(() => {
    syncActiveLog()
  }, [activeLog])

  useInterval(() => syncActiveLog(), 10000)

  useEffect(() => {
    if(logContent) {
      if(logRef?.current?.scrollTo) {
        logRef?.current?.scrollTo(0, logRef?.current?.scrollHeight)
      }
    }
  }, [logContent])

  const syncLogs = () => {
    if(state.project?._id && !isLoadingViewer) {
      setIsLoadingViewer(true)
      var projectID = state.project?._id
      Axios.post(`/project/details`, {projectID}).then(async response => {
        if(isMounted) {
          if(response.status === 200 && response.data) {
            const project = response.data
            if(project?.versionDetails?.allVersions?.length > 0) {
              var showVersion = project.versionDetails.allVersions.filter(v => v.version === state?.workingOnVersion)
              if(showVersion.length > 0) {
                setResult(showVersion[0])
                // todo: default log selection - localStorage
                if(showVersion[0]?.output?.length > 0) {
                  setActiveLog(showVersion[0].output[0])
                } else {
                  setActiveLog(null)
                }
                setErrorMsg(null)
              } else {
                setErrorMsg('Error Loading Logs')
              }
            } else {
              setErrorMsg('Error Loading Logs')
            }
          } else {
            setErrorMsg('Error Loading Logs')
          }
        }
        setIsLoadingViewer(false)
      }).catch(err => {
        if(isMounted) {
          setErrorMsg('Error Loading Logs')
          setIsLoadingViewer(false)
        }
      })
    }
  }

  const syncActiveLog = () => {
    if(activeLog && !isLoadingLog) {
      var outputType = null
      if(activeLog === 'compute-output.log') {
        outputType = 'compute-output'
      } else if(activeLog === 'deploy-output.log') {
        outputType = 'deploy-output'
      } else {
        return
      }
      setIsLoadingLog(true)
      downloadOutput(outputType).then(content => {
        if(isMounted) {
          setIsLoadingLog(false)
          setLogContent(content)
          setErrorMsg(null)
        }
      }).catch(err => {
        if(isMounted) {
          setIsLoadingLog(false)
          setErrorMsg('Error Loading Log')
        }
      })
    }
  }

  // if(state.logMinimized) return null

  if(isLoadingViewer) {
    return (
      <div className='sv2-spdrn-logs-container'>
        <div className='sv2-spdrn-logs' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          Fetching Logs&nbsp;&nbsp;<Spinner style={{borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)'}}/>
        </div>
      </div>
    )
  }

  var logTabs = []
  if(result?.output?.length > 0) {
    logTabs = result.output.map((l, i) => {
      if(l === 'compute-output.log') {
        return (
          <span key={i}>main.py</span>
        )
      } else {
        return (
          <span key={i}>function.py</span>
        )
      }
    })
  }

  return (
    <div className='sv2-spdrn-logs-container'>
      <div className='sv2-spdrn-logs'>
        <div className='sv2-spdrn-log-header'>
          Logs&nbsp;•&nbsp;{logTabs.length > 0 ? logTabs : 'None'}&nbsp;&nbsp;{isLoadingLog && <Spinner style={{borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)'}}/>}
          &nbsp;
          <div style={{
            position: 'absolute', top: '0px', right: '15px', height: '30px',
            display: 'flex', alignItems: 'center', justifyContent: 'center'
          }}>
            <span style={{color: 'crimson', opacity: 1}}>{errorMsg}</span>&nbsp;&nbsp;
            <div className='sv2-spdrn-save-btn'
              onClick={() => { setState({logMinimized: true}) }}
            >
              <i className="far fa-window-minimize"></i>
            </div>
          </div>
        </div>
        { logContent ?
          <div className='sv2-spdrn-log-content' ref={logRef}>
            {logContent}
          </div>
          :
          <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            No Log
          </div>
        }
      </div>
    </div>
  )
}

const SimpleCodePanel = ({}) => {
  const { editorRef, sandboxState, sandboxSetState } = useContext(SandBoxContext)
  const scriptRef = useRef(sandboxState.script)

  useEffect(() => {
    var timoutID0 = setTimeout(() => {
      try {
        var t = editorRef.current.editor.getElementsByTagName('grammarly-extension')
        t[0].style.setProperty('display', 'none')
      } catch (error) {
        // nothing to do here
      }
    }, 500)
    var timoutID1 = setTimeout(() => {
      try {
        var t = editorRef.current.editor.getElementsByTagName('grammarly-extension')
        t[0].style.setProperty('display', 'none')
      } catch (error) {
        // nothing to do here
      }
    }, 3000)

    return () => {
      clearTimeout(timoutID0)
      clearTimeout(timoutID1)
      sandboxSetState({script: scriptRef.current})
    }
  }, [])

  var editable = true
  if(sandboxState?.metadata?.editorActive === false) editable = false
  return(
    <div style={{width: '100%', height: 'calc(100% - 145px)', display: 'flex'}}>
    <div className='simple-editor'>
      <CodeMirror
        ref={editorRef}
        value={sandboxState.script || ''}
        onChange={(e) => {
          scriptRef.current = e
        }}
        className='sv2-codemirror-configure'
        height='100% !important'
        editable={editable}
        autoFocus={true}
        theme={atomone}
        extensions={[python()]}
        indentWithTab={true}
        basicSetup={{
          lineNumbers: true,
          highlightActiveLineGutter: true,
          highlightSpecialChars: true,
          history: true,
          foldGutter: true,
          drawSelection: true,
          dropCursor: true,
          allowMultipleSelections: true,
          indentOnInput: true,
          syntaxHighlighting: true,
          bracketMatching: true,
          closeBrackets: true,
          autocompletion: true,
          rectangularSelection: true,
          crosshairCursor: true,
          highlightActiveLine: true,
          highlightSelectionMatches: true,
          closeBracketsKeymap: true,
          defaultKeymap: true,
          searchKeymap: true,
          historyKeymap: true,
          foldKeymap: true,
          completionKeymap: true,
          lintKeymap: true
        }}
      />
    </div>
    </div>
  )
}

const SimpleJSONEditor = ({script='', placeHolder='', height='130px', onChange=()=>{}}) => {
  const { jsonEditorRef, sandboxState } = useContext(SandBoxContext)
  useEffect(() => {
    setTimeout(() => {
      try {
        var t = jsonEditorRef.current.editor.getElementsByTagName('grammarly-extension')
        t[0].style.setProperty('display', 'none')
      } catch (error) {
        // nothing to do here
      }
    }, 500)
    setTimeout(() => {
      try {
        var t = jsonEditorRef.current.editor.getElementsByTagName('grammarly-extension')
        t[0].style.setProperty('display', 'none')
      } catch (error) {
        // nothing to do here
      }
    }, 3000)
  }, [])
  var editable = true
  if(sandboxState?.metadata?.jsonEditorActive === false) editable = false
  return(
    <div className='simple-editor' style={{height: height, borderRadius: '5px', border: 'none'}}>
      <CodeMirror
        ref={jsonEditorRef}
        value={script}
        placeholder={placeHolder}
        className='sv2-codemirror-mini-configure'
        height='100% !important'
        editable={editable}
        autoFocus={false}
        theme={atomone}
        extensions={[json()]}
        indentWithTab={true}
        basicSetup={{
          lineNumbers: false,
          highlightActiveLineGutter: true,
          highlightSpecialChars: true,
          history: true,
          foldGutter: true,
          drawSelection: true,
          dropCursor: true,
          allowMultipleSelections: true,
          indentOnInput: true,
          syntaxHighlighting: true,
          bracketMatching: true,
          closeBrackets: true,
          autocompletion: true,
          rectangularSelection: true,
          crosshairCursor: true,
          highlightActiveLine: true,
          highlightSelectionMatches: true,
          closeBracketsKeymap: true,
          defaultKeymap: true,
          searchKeymap: true,
          historyKeymap: true,
          foldKeymap: true,
          completionKeymap: true,
          lintKeymap: true
        }}
      />
    </div>
  )
}


export {AIPanel, CodePanel, LogViewer, SimpleCodePanel, SimpleJSONEditor}