import React, { useContext, useState } from 'react'
import { RightPanel, RightPanelBasicTitle } from '../panel-utils'
import Agent from '../../title'
import { FlowBasic, FlowButton, FlowDropDown, FlowOverlayPrompt, FlowStackedBody, FlowTags } from '../../../../components/flow-ui/flow-blocks'
import SandBoxContext from '../../../../contexts/SandBoxContext'
import '../styles.scss'
import { Context } from '../../../../contexts/GlobalContext'
import supportedServices, { getCompatibleServices, getServiceWithID } from './supportedServices'
import Axios from '../../../../utilities/axios'
import Spinner from '../../../../components/spinner'

const LLMServicePanel = () => {
  const {sandboxState, sandboxSetState, setOverlay, temporaryStateManagement} = useContext(SandBoxContext)
  const {globalState} = useContext(Context)
  var serviceKeys = Object.keys(sandboxState?.services || {})

  const handleLogin = () => {
    setOverlay(
      <FlowOverlayPrompt
        title='Sign In Required'
        promptMsg={
        <>
          You are not signed in! You have to sign in in order to utilize your web services with <Agent/>.
        </>
        }
        titleIcon={<i className="fal fa-exclamation-triangle" style={{color: 'orange'}}></i>}
        proceedMsg={<><i className="fal fa-check" style={{color: 'rgb(29, 191, 113)'}}></i>&nbsp;Sign In</>}
        cancelMsg={<><i className="fal fa-times" style={{color: 'crimson'}}></i>&nbsp;Cancel</>}
        onProceed={() => {
          localStorage.setItem('code-initiation', 'true')
          temporaryStateManagement('save')
          window.location.href = '/login?continue=integrate'
          setOverlay(null)
        }}
        onCancel={() => {
          setOverlay(null)
        }}
      />
    )
  }

  const handleOnSelection = (service) => {
    setOverlay(<OnboardingProceed serviceName={service}/>)
  }

  let options = getCompatibleServices(serviceKeys)

  return (
    <RightPanel>
      <RightPanelBasicTitle>
        <div className='sndbx-panel-close'
          onClick={() => {
            sandboxSetState({showSidePanel: false})
          }}
        >
          <i className="far fa-chevron-right"></i>
        </div>
        <i className="fal fa-sparkles" style={{color: 'rgb(29, 191, 113)'}}></i>&nbsp;&nbsp;Services Panel
      </RightPanelBasicTitle>
      <div style={{width: '100%', height: '100%', overflowY: 'scroll', padding: '10px'}}>
        <FlowBasic
          title='Services'
          titleIcon={<i className="fal fa-sparkles"></i>}
          border={'2px solid rgba(255, 255, 255, 0.2)'}
        >
          <div className='sv2-flow-stacked-body' style={{height: 'auto', alignItems: 'unset'}}>
            <div className='title-icon'>
              <div className='square'
                style={{bottom: '12px'}}
              ></div>
            </div>
            <div style={{marginTop: '4px', width: '100%'}}>
              <div style={{backgroundColor: 'rgba(255,255, 255, 0.05)', borderRadius: '5px', padding: '10px', lineHeight: 1.3, fontFamily: 'Roboto Mono'}}>
                You can add web services like, for instance email, so that <Agent/> can utilize them to get tasks done on your behalf.
              </div>
              <FlowStackedBody showStep={false}>
                <div style={{width: '100%', display: 'inline-flex', alignItems: 'center', marginTop: '12.5px'}}>
                  <div style={{flexGrow: 1}}>
                    Web Services
                  </div>
                  <FlowDropDown
                    selected=''
                    // options={serviceKeys.length > 0 && serviceKeys[0] === 'email-service' ? [] : ['Email (Gmail)']}
                    options={options}
                    onSelection={ elem => {
                      // check sign-in
                      if(globalState === null || globalState === undefined || globalState.user === undefined || globalState.user === null) handleLogin()
                      else handleOnSelection(elem)
                    }}
                    initialMsg='Select Service'
                    emptyMsg='No Service Available'
                  />
                </div>
              </FlowStackedBody>
              {serviceKeys.length > 0 ? <br/> : null}
              {
                serviceKeys.map((elem, i) => (
                  <ServiceControlBlock key={i} service={sandboxState?.services[elem]}/>
                ))
              }
            </div>
          </div>
        </FlowBasic>
      </div>
    </RightPanel>
  )
}

export default LLMServicePanel

const ServiceControlBlock = ({service}) => {
  const {setOverlay} = useContext(SandBoxContext)
  let settings = null
  // console.log(service)
  // if not an empty-service (the default for no services) then must have controls
  // serviceID is mapped
  if(service.serviceID !== 'empty-service') {
    settings = (
      <div style={{marginTop: '10px'}}>
        <FlowTags
          styles={{
            fontSize: '10px',
            backgroundColor: 'rgba(255, 255, 255, 0.07)',
          }}
        >
          {
            service?.isActive ?
            <>
              <i style={{color: 'rgb(29, 191, 113)'}} className="fas fa-circle"></i> Active
            </>
            :
            <>
              <i style={{color: 'orange'}} className="fas fa-circle"></i> Inactive
            </>
          }
        </FlowTags>
        <span className='sndbx-context-ctrl'
          onClick={() => {
            // setOverlay(<ServiceSpecificControl key={`${service.serviceID}-${service.isActive}`} service={service}/>)
            setOverlay(<ServiceSpecificControl serviceID={service.serviceID}/>)
          }}
        >
          <FlowTags
            styles={{
              fontSize: '10px',
              backgroundColor: 'rgba(255, 255, 255, 0.12)',
              color: 'inherit'
            }}
          ><i className="fal fa-tools"></i> Settings</FlowTags>
        </span>
      </div>
    )
  }
  let extra = null
  if(service?.extra) {
    extra = (
      <div style={{backgroundColor: 'rgba(0, 0, 0, 0.1)', marginTop: '10px', lineHeight: 1.3, borderRadius: '5px', padding: '7px 10px', fontSize: '10px'}}>
        {service?.extra}
      </div>
    )
  }
  return(
    <div className='sndbx-context-blocks'>
      <i className={service.icon}></i>&nbsp;{service.title}<span style={{float: 'right'}}>{service.value}</span>
      {extra}
      {settings}
    </div>
  )
}

const OnboardingProceed = ({serviceName=''}) => {
  const {setOverlay, temporaryStateManagement} = useContext(SandBoxContext)
  let serviceDetails = null
  if(serviceName in supportedServices) {
    serviceDetails = supportedServices[serviceName]
  }
  if(!serviceDetails) return null
  return(
    <FlowOverlayPrompt
      title={serviceDetails.title}
      promptMsg={serviceDetails.value}
      titleIcon={<i className={serviceDetails.icon}></i>}
      proceedMsg={<><i className="fal fa-check" style={{color: 'rgb(29, 191, 113)'}}></i>&nbsp;Proceed</>}
      cancelMsg={<><i className="fal fa-times" style={{color: 'crimson'}}></i>&nbsp;Cancel</>}
      onProceed={() => {
        localStorage.setItem('code-initiation', 'true')
        temporaryStateManagement('save')
        window.location.href = serviceDetails.link
        setOverlay(null)
      }}
      onCancel={() => {
        setOverlay(null)
      }}
      consent={serviceDetails?.consent || null}
    />
  )
}

const ServiceSpecificControl = ({serviceID}) => {
  const {syncUser} = useContext(Context)
  const {sandboxState, setOverlay} = useContext(SandBoxContext)
  const [isLoading, setIsLoading] = useState(false)
  const [isRemoveLoading, setIsRemoveLoading] = useState(false)
  const [popUpMsg, setPopUpMsg] = useState(null)

  if(!serviceID) return null
  const service = sandboxState.services[serviceID]
  if(!service) return null
  // console.log('sandboxState.services =>', sandboxState.services)
  // console.log('serviceSpecificControl', service)

  const statusToggleHandler = ({status, serviceID}) => {
    if(isLoading) return null
    if(!status || !serviceID) return null
    setIsLoading(true)

    Axios.post('service/toggle-status', {status, serviceID}).then(response => {
      syncUser(false, () => {
        setIsLoading(false)
      })
    }).catch(err => {
      setIsLoading(false)
    })
  }

  const serviceRemoveHandler = ({serviceID}) => {
    if(isRemoveLoading) return null
    if(!serviceID) return null
    setIsRemoveLoading(true)

    Axios.get(`service/remove/${serviceID}`).then(response => {
      setOverlay(null)
      syncUser(false, () => {
        setIsRemoveLoading(false)
      })
    }).catch(err => {
      setOverlay(null)
      setIsRemoveLoading(false)
    })
  }

  // console.log(service)
  // service.serviceID (from getServiceContext function) is the unique serviceID from backend
  let serviceDetails = getServiceWithID(service?.serviceID)
  if(!serviceDetails) {
    return null
  }
  let promptMsg = 
  <>
  {/* Service {serviceDetails.serviceKey} is {service?.isActive ? 'active' : 'inactive'} */}
  Service Status&nbsp;&nbsp;<FlowTags
    styles={{
      fontFamily: 'Roboto Mono',
      fontSize: '10px',
      backgroundColor: 'rgba(255, 255, 255, 0.07)',
    }}
  >
    {
      service?.isActive ?
      <>
        <i style={{color: 'rgb(29, 191, 113)'}} className="fas fa-circle"></i> Active
      </>
      :
      <>
        <i style={{color: 'orange'}} className="fas fa-circle"></i> Inactive
      </>
    }
  </FlowTags>

  <br/><br/>
  {
    !service?.isActive ?
    <>
      <div style={{marginBottom: '5px'}}>
        You can activate this service. Upon activation, Nelima will be able to utilize this specific feature. You can deactivate the service again later.
      </div>
      <FlowButton style={{
        lineHeight: 1, fontSize: '10px', padding: '3px 7px', userSelect: 'none'
      }}
        onClick={() => {
          // activate handler
          if(service?.serviceID) {
            statusToggleHandler({status: 'active', serviceID: service?.serviceID})
          }
        }}
      >
        {
          isLoading ?
          <Spinner style={{borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)', height: '10px', width: '10px', borderWidth: '1.5px'}}/>
          :
          <i className="fal fa-check" style={{color: 'rgb(29, 191, 113)'}}></i>
        }
        &nbsp;Activate Service
      </FlowButton>
    </>
    :
    <>
      <div style={{marginBottom: '5px'}}>
        You can deactivate this service. When the service is inactive, Nelima will not have access to utilize this specific feature. You can activate the service again later.
      </div>
      <FlowButton style={{
        lineHeight: 1, fontSize: '10px', padding: '3px 7px', userSelect: 'none'
      }}
        onClick={() => {
          if(service?.serviceID) {
            statusToggleHandler({status: 'inactive', serviceID: service?.serviceID})
          }
        }}
      >
        {
          isLoading ?
          <Spinner style={{borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)', height: '10px', width: '10px', borderWidth: '1.5px'}}/>
          :
          <i className="fal fa-exclamation-triangle" style={{color: 'orange'}}></i>
        }
        &nbsp;Deactivate Service
      </FlowButton>
    </>
  }
  <br/><br/>
  <div>
    You can also remove the service integration from Nelima. By removing the integration, we will remove the access granted by the 3rd Party App to take action on your behalf.
  </div>
  </>
  return(
    <FlowOverlayPrompt
      title={<>Service Settings - {serviceDetails.serviceKey}</>}
      promptMsg={promptMsg}
      titleIcon={<i className={serviceDetails.icon}></i>}
      proceedMsg={<><i className="fal fa-times-circle" style={{color: 'crimson'}}></i>&nbsp;Remove</>}
      cancelMsg={<>Close</>}
      onProceed={() => {
        // handle remove
        // setOverlay(null)
        serviceRemoveHandler({serviceID: service?.serviceID})
        // reload page
      }}
      onCancel={() => {
        setOverlay(null)
      }}
      consent={<>I am aware that access to {serviceDetails.serviceKey} will be permanently removed.</>}
    />
  )
}