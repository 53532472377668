import React, { useContext, useEffect, useRef, useState } from 'react'
import {functionPropStyle} from '../../pages/SpeedRun/sandbox/dataType'
import './styles.scss'
import SandBoxContext from '../../contexts/SandBoxContext'
import { SimpleJSONEditor } from '../../pages/SpeedRun/ConsoleKeyComponents'

const FlowBase = ({children=null, style = {}}) => (
  <div className='sv2-flowbase' style={style}>
    {children}
  </div>
)

const FlowBasic = ({
  title='Flow Title',
  titleIcon=null,
  children=null,
  border=null,
  style={}
}) => {
  return(
    <FlowBase style={{border, ...style}}>
      <FlowTitle title={title} icon={titleIcon}/>
      {children}
    </FlowBase>
  )
}

const FlowTitle = ({title, icon=null}) => (
  <div className='sv2-flowtitle'>
    {icon ? 
      <div className='title-icon'>
        {icon}
      </div>
      : 
      null
    }
    <span className='title-mod'>{title}</span>
  </div>
)

const FlowStackedBody = ({children, showStep=true, icon=null}) => (
  <div className='sv2-flow-stacked-body'>
    {showStep ? 
      <div className='title-icon'>
        <div className='square'></div>
      </div>
      : 
      null
    }
    {
      icon ?
      <div className='title-icon'>
        {icon}
      </div>
      :
      null
    }
    <span className='title-mod'
      style={showStep || icon ? {} : {paddingLeft: '10px'}}
    >{children}</span>
  </div>
)

const FlowTags = ({children, color, styles=null}) => {
  return (
    <span className='sv2-flow-tags' style={{
      ...color ? {backgroundColor: color} : {},
      ...styles
    }}>
      {children}
    </span>
  )
}

const FlowDropDown = ({
    options, 
    selected=null, 
    initialMsg='Select', 
    onCheck=()=>{}, 
    onSelection=()=>{}, 
    color='inherit', 
    emptyMsg='None',
    disabled=false,
    width='default' // don't use this, just for front page demo
  }) => {
  const [showMenu, setShowMenu] = useState(false)
  const ref = useRef(null)
  useEffect(() => {
    if(color) {
      ref.current.style.setProperty('--flow-dropdown-color', color)
    }
  }, [])

  if(disabled) {
    return (
      <div className='sv2-flow-dropdown' ref={ref}>
        <div className='sv2-flow-dropdown-block' style={{cursor: 'not-allowed'}}>
          <div style={{flexGrow: 1, display: 'flex', alignItems: 'center', paddingLeft: '5px', filter: 'brightness(70%)'}}><i className="fas fa-caret-down"></i></div>
          <div className='sv2-flow-dropdown-options sv2-flow-ip-manager' style={{cursor: 'not-allowed', ...width !== 'default' ? {width} : {}}}>
            { selected ? <span style={{float: 'right', color: color}}>{selected}</span> : initialMsg }
          </div>
        </div>
      </div>  
    )
  }
  
  return (
    <div className='sv2-flow-dropdown' ref={ref}>
      <div className='sv2-flow-dropdown-block'
        onClick={() => {
          onCheck()
          setShowMenu(!showMenu)
        }}
      >
        <div style={{flexGrow: 1, display: 'flex', alignItems: 'center', paddingLeft: '5px'}}>
          {showMenu ? 
            <i className="fas fa-caret-up"></i>
            :
            <i className="fas fa-caret-down"></i>
          }
        </div>
        <div className='sv2-flow-dropdown-options sv2-flow-ip-manager'>
          {
            selected ?
            <span style={{float: 'right', color: color}}>{selected}</span>
            :
            initialMsg
          }
        </div>
      </div>
      {
        showMenu ?
        <div className='sv2-flow-dropdown-expand'>
          {
            options.length > 0 ?
            options.map((elem, idx) => (
              <div className='sv2-flow-dropdown-block' key={`${Math.random()}-${idx}`}
                style={elem === selected ? {color: color} : {}}
                onClick={() => {
                  onSelection(elem)
                  setShowMenu(false)
                }}
              >
                <div style={{flexGrow: 1, display: 'flex', alignItems: 'center', paddingLeft: '5px', color: 'transparent'}}>
                  <i className="fas fa-caret-down"></i>
                </div>
                <div className='sv2-flow-dropdown-options sv2-flow-ip-manager'>
                  {elem || 'undefined'}
                </div>
              </div>
            ))
            :
            <div className='sv2-flow-dropdown-block'
                onClick={() => setShowMenu(false)}
            >
              <div style={{flexGrow: 1, display: 'flex', alignItems: 'center', paddingLeft: '5px', color: 'transparent'}}>
                <i className="fas fa-caret-down"></i>
              </div>
              <div className='sv2-flow-dropdown-options sv2-flow-ip-manager'>
                {emptyMsg}
              </div>
            </div>
          }
        </div>
        :
        null
      }
    </div>
  )
}

const FlowAttribute = ({children, style=null}) => (
  <div className='sv2-flow-attribute' style={style}>
    {children}
  </div>
)

const FlowButton = ({children, style=null, onClick=() => {}, disabled=false}) => (
  <div 
    className={`sv2-flow-attribute sv2-flow-btn${disabled ? ' sv2-flow-btn-disabled' : ''}`}
    style={style} 
    onClick={onClick}
  >
    {children}
  </div>
)

const FlowElement = ({attributeName, attributeType, required=true, hasEnum=true, addButton=false, mark=null}) => {
  const {sandboxSetState, deleteObjectDetails} = useContext(SandBoxContext)
  // console.log(attributeName, mark)
  if(addButton) {
    return(
      <div className='sv2-flow-funcprop-row'>
        <div className='sv2-flow-row-attr details'>
          <div className='sv2-flow-dtype sv2-flow-dtype-btn' style={{maxWidth: '200px', padding: '3px 6px'}}
            onClick={() => {
              sandboxSetState({objectSetup: {
                objectName: '',
                objectType: 'new-arg' // creates a new-argument
              }})
            }}
          >
            <i className="fal fa-plus"></i>&nbsp;Add Argument</div>
        </div>
      </div>
    )
  }
  var theme = null
  if(attributeType) {
    theme = {
      icon: attributeType,
      color: 'rgb(229, 192, 123)'
    }
    if(attributeType in functionPropStyle) {
      theme = functionPropStyle[attributeType]
    }
  }

  return (
    <div className='sv2-flow-funcprop-row'>
      <div className='sv2-flow-row-attr'>
        <div className='name' style={attributeType === 'function' ? { color: theme.color} : { color: 'inherit'}}>
          {attributeName}
        </div>
      </div>
      <div className='sv2-flow-row-attr dtype'>
        {
          theme ?
            <div className='sv2-flow-dtype' style={{color: theme.color}}>
              {theme.icon || 'Unknown'}
            </div>
            :
            null
        }
      </div>
      <div className='sv2-flow-row-attr details'>
        <div className='sv2-flow-dtype sv2-flow-dtype-btn'
          onClick={() => {
            sandboxSetState({objectSetup: {
              objectName: attributeName,
              objectType: attributeType
            }})
          }}
        >
          {/* DETAILS&nbsp;<i className="fas fa-pen"></i> */}
          UPDATE
          { mark === 'check' ? 
            <i className="fal fa-check" style={{marginLeft: '4px', color: 'rgb(29, 191, 113)'}}></i> 
            : mark === 'warn' ? 
              <i className="fal fa-exclamation-circle" style={{marginLeft: '4px', color: 'orange'}}></i> 
                : null
          }
          {/* &nbsp;<i className="fas fa-pen"></i> */}
        </div>
        {
          attributeType === 'function' ?
          null
          :
          <>
            {required ? <div className='sv2-flow-dtype' style={{color: 'inherit'}}>REQ</div> : null}
            {hasEnum ? <div className='sv2-flow-dtype' style={{fontWeight: 700, color: 'rgb(192, 121, 221)'}}>Enum</div> : null}
          </>
        }
      </div>
      <div className='sv2-flow-row-attr delete'
        onClick={() => {
          if(attributeType !== 'function') {
            deleteObjectDetails(attributeName)
          }
        }}
      >
        {
        attributeType === 'function' ? 
          null
          :
          <i className="fal fa-times delete-btn"></i>
        }
      </div>
    </div>
  )
}

const FlowParamValue = ({attributeName, attributeType, required=true, hasEnum=true, addButton=false}) => {
  const {sandboxSetState, deleteObjectDetails} = useContext(SandBoxContext)
  if(addButton) {
    return(
      <div className='sv2-flow-funcprop-row'>
        <div className='sv2-flow-row-attr details'>
          <div className='sv2-flow-dtype sv2-flow-dtype-btn' style={{maxWidth: '200px', padding: '3px 6px'}}
            onClick={() => {
              sandboxSetState({objectSetup: {
                objectName: '',
                objectType: 'new-arg' // creates a new-argument
              }})
            }}
          >
            <i className="fal fa-plus"></i>&nbsp;Add Argument</div>
        </div>
      </div>
    )
  }
  var theme = {
    icon: attributeType,
    color: 'rgb(229, 192, 123)'
  }
  if(attributeType in functionPropStyle) {
    theme = functionPropStyle[attributeType]
  }

  return (
    <div className='sv2-flow-funcprop-row'>
      <div className='sv2-flow-row-attr'>
        <div className='name' style={attributeType === 'function' ? { color: theme.color} : { color: 'inherit'}}>{attributeName}</div>
      </div>
      <div className='sv2-flow-row-attr dtype'>
        <div className='sv2-flow-dtype' style={{color: theme.color}}>
          {theme.icon || 'None'}
        </div>
      </div>
      <div className='sv2-flow-row-attr details'>
        <div className='sv2-flow-dtype sv2-flow-dtype-btn'
          onClick={() => {
            sandboxSetState({objectSetup: {
              objectName: attributeName,
              objectType: attributeType
            }})
          }}
        >DETAILS&nbsp;<i className="fas fa-pen"></i></div>
      </div>
      <div className='sv2-flow-row-attr delete'
        onClick={() => {
          if(attributeType !== 'function') {
            deleteObjectDetails(attributeName)
          }
        }}
      >
        {
        attributeType === 'function' ? 
          null
          :
          <i className="fal fa-times delete-btn"></i>
        }
      </div>
    </div>
  )
}

const FlowConnector = ({length=40, hasMid=false, children=null}) => (
  <div className='sv2-flowbase-connector' style={{height: `${length}px`}}>
    <div className='start'></div>
    <div className='line'></div>
    {
      hasMid ? 
      <div className='mid'>
        <div className='mid-label'>
          {children}
        </div>
      </div> : null
    }
    <div className='end'></div>
  </div>
)

const FlowModal = ({children}) => (
  <div className='sv2-flow-modal'>
    {children}
  </div>
)

const FlowInput = ({value=null, placeHolder='', onChange=() => {}, style={}, onSubmit=() => {}, disabled=false}) => {
  return (
    <input
      className='sv2-flow-input sv2-flow-ip-width'
      value={value}
      disabled={disabled}
      placeholder={placeHolder}
      style={style}
      onChange={e => onChange(e)}
      onKeyDown={e => {
        if(e.key === 'Enter') {
          onSubmit()
          e.preventDefault()
        }
      }}
    />
  )
}

const FlowEnum = ({dType, defaultEnums=[], onAdd=() => {}}) => {
  const [enumList, setEnumList] = useState(defaultEnums)
  const [currentInput, setCurrentInput] = useState('')
  var isValidAdd = currentInput?.length > 0

  const castValue = (dType, value) => {
    value = String(value).trim()
    if(dType === 'int') {
      return parseInt(value)
    } else if(dType === 'float') {
      return parseFloat(value)
    } else if(dType === 'str') {
      return String(value)
    }
  }

  const placeHolder = {
    'int': 'Integer Value',
    'float': 'Float Value',
    'str': 'String Value'
  }

  return (
    <>
      <FlowStackedBody showStep={false}>
        <div style={{width: '100%', display: 'inline-flex', alignItems: 'center'}}>
          <div style={{flexGrow: 1, fontFamily: 'Roboto Mono'}}>Enum (Optional)</div>
          <div style={{position: 'relative'}}>
            <FlowInput
              value={currentInput || ''}
              placeHolder={placeHolder[dType] || 'Value'}
              style={{paddingRight: '25px'}}
              onChange={e => {
                setCurrentInput(e.target.value)
              }}
              onSubmit={() => {
                let current = castValue(dType, currentInput)
                if(current) {
                  let temp = [...enumList]
                  if(!temp.includes(current)) {
                    temp.push(current)
                    setEnumList(temp)
                    onAdd(temp)
                  }
                  setCurrentInput('')
                }
              }}
            />
            <div className='sv2-flow-enum-add'
              style={isValidAdd ? {color: 'rgb(29, 191, 113)'} : {}}
              onClick={() => {
                let current = castValue(dType, currentInput)
                if(current) {
                  let temp = [...enumList]
                  if(!temp.includes(current)) {
                    temp.push(current)
                    setEnumList(temp)
                    onAdd(temp)
                  }
                  setCurrentInput('')
                }
              }}
            >
              <i className="far fa-plus"></i>
            </div>
          </div>
        </div>
      </FlowStackedBody>
      {
        enumList?.length > 0 ?
        <div className='sv2-flow-stacked-body' style={{height: 'auto', alignItems: 'unset'}}>
          <div className='title-icon'>
            <div className='square'
              style={{bottom: '12px'}}
            ></div>
          </div>
          <div className='sv2-flow-enum-collections' style={{
            marginTop: '4px',
          }}>
            {
              enumList.map((elem, idx) => (
                <span className='sv2-flow-enum-tag' key={`${elem}-${idx}`} 
                  onClick={() => {
                    let temp = [...enumList]
                    temp = temp.filter(item => item !== elem)
                    setEnumList(temp)
                    onAdd(temp)
                  }
                }>
                  {elem}&nbsp;<i className="fal fa-times"></i>
                </span>
              ))
            }
          </div>
        </div>
        :
        null
      }
    </>
  )
}

const FlowTextBox = ({value=null, placeHolder='', onChange=() => {}, style={}}) => {
  return (
    <textarea
      className='sv2-flow-textarea'
      value={value}
      placeholder={placeHolder}
      onChange={e => onChange(e)}
      style={style}
    >
    </textarea>
  )
}

const FlowSamplePrompts = ({defaultSamples=[], placeHolder='', style={}, onAdd=() => {}}) => {
  const [promptList, setPromptList] = useState(defaultSamples)
  const [currentPrompt, setCurrentPrompt] = useState('')
  var isValidAdd = currentPrompt?.length > 0
  return (
    <>
      <div style={{position: 'relative'}}>
        <textarea
          className='sv2-flow-textarea sv2-flow-propmt-textarea'
          placeholder={placeHolder}
          onChange={e => setCurrentPrompt(e.target.value)}
          style={{ ...style}}
          value={currentPrompt}
        >
        </textarea>
        <div className='sv2-flow-enum-add sv2-flow-prompt-add'
          style={isValidAdd ? {color: 'rgb(29, 191, 113)'}: {}}
          onClick={() => {
            // add prompt
            let curr = String(currentPrompt).trim()
            if(curr) {
              setCurrentPrompt('')
              let temp = [...promptList]
              temp.push(curr)
              setPromptList(temp)
              onAdd(temp)
            }
          }}
        >
          <i className="far fa-plus"></i>
        </div>
      </div>
      {
        promptList?.length > 0 ?
        <div className='sv2-flow-stacked-body' style={{height: 'auto', alignItems: 'unset'}}>
          <div className='sv2-flow-enum-collections' style={{
            marginTop: '4px',
            width: '100%'
          }}>
            {
              promptList.map((elem, idx) => (
                <span 
                  className='sv2-flow-enum-tag'
                  style={{width: '100%', lineHeight: 1.3, position: 'relative', paddingRight: '10px'}}
                  key={`${Math.random()}-${idx}`} 
                  onClick={() => {
                    let temp = [...promptList]
                    temp.splice(idx, 1)
                    setPromptList(temp)
                    onAdd(temp)
                  }
                }>
                  {elem}&nbsp;
                  <div style={{
                    position: 'absolute',
                    top: '2.5px',
                    right: '4px'
                  }}>
                    <i className="fal fa-times"></i>
                  </div>
                </span>
              ))
            }
          </div>
        </div>
        :
        null
      }
    </>
  )
}

const FlowTestParams = ({defaultParams=[], placeHolder=''}) => {
  var placeHolder = '{\n  "argument_one": "Hello World!",\n  "argument_two": 10,\n  "argument_three": 3.1415926,\n  "argument_four": null\n}'
  return (
    <>
      <div style={{position: 'relative'}}>
        <SimpleJSONEditor
          script={placeHolder}
        />
      </div>
    </>
  )
}

const FlowOverlayPrompt = ({
  title='Overlay Title',
  titleIcon=null,
  promptMsg='Overlay Prompt Message',
  cancelMsg='Cancel',
  proceedMsg='Proceed',
  onProceed=() => {},
  onCancel=() => {},
  singleButton=false,
  consent=null
}) => {
  const [agreementState, setAgreementState] = useState(consent === null ? 'not-applicable' : 'not-agreed')
  return (
    <FlowModal>
      <FlowBasic 
        title={title}
        titleIcon={titleIcon}
        border={'2px solid rgba(255, 255, 255, 0.2)'}
      >
        <div style={{marginTop: '10px', lineHeight: 1.5, fontSize: '11px', fontWeight: 500, padding: '0 10px'}}>
          {promptMsg}
          {consent && (agreementState === 'not-agreed' || agreementState === 'agreed') ? 
            <>
              <div style={{marginTop: '15px', marginLeft: '0px', display: 'inline-flex', alignItems: 'center'}}>
                {
                  agreementState === 'agreed' ?
                    <i className="fas fa-check-square" style={{ cursor: 'pointer', fontSize: '18px'}}
                      onClick={() => {
                        setAgreementState('not-agreed')
                      }}
                    ></i>
                    :
                    <i className="far fa-square" style={{ cursor: 'pointer', fontSize: '18px'}}
                      onClick={() => {
                        setAgreementState('agreed')
                      }}
                    ></i>
                }
                <div style={{marginLeft: '10px'}}>
                  {consent}
                </div>
              </div>
            </>
            :
            null
          }
        </div>
        <div style={{marginTop: '20px', textAlign: 'center'}}>
          {
            !singleButton ?
            <>
              <FlowButton style={{
                lineHeight: 1, fontSize: '12px', padding: '3px 7px',
                userSelect: 'none'
              }}
                onClick={onCancel}
              >
                {cancelMsg}
              </FlowButton>&nbsp;&nbsp;&nbsp;&nbsp;
            </>
            :
            null
          }
          <FlowButton style={{
            lineHeight: 1, fontSize: '12px', padding: '3px 7px',
            userSelect: 'none',
          }}
            disabled={agreementState === 'not-agreed'}
            onClick={() => {
              if(agreementState === 'not-agreed') {
                return
              }
              onProceed()
            }}
          >
            {proceedMsg}
          </FlowButton>
        </div>
      </FlowBasic>
    </FlowModal>
  )
}

export {
  FlowBase,
  FlowBasic,
  FlowDropDown,
  FlowTags,
  FlowStackedBody,
  FlowAttribute,
  FlowButton,
  FlowElement,
  FlowTitle,
  FlowConnector,
  FlowModal,
  FlowInput,
  FlowTextBox,
  FlowEnum,
  FlowSamplePrompts,
  FlowOverlayPrompt,
  FlowParamValue,
  FlowTestParams
}